const datacapture = {

    companyName: function() {
        return marketplaceCompany;
    },

    getData: function(e) {
        // console.log("Event", e);
        // console.log("target", e.target);
        // console.log("data", e.target.dataset.company);
        let splits = (window.location + "").split("#");
        if (splits.length > 1) {
            window.location = splits[0] + "#hirer-marketplace-demo-popup";
        } else {
            window.location = window.location + "#hirer-marketplace-demo-popup";
        }
        const companyName = e.target.dataset.company;
        marketplaceCompany = companyName;
        
        datacapture.initModal(companyName);

    },
    
    getFormDataString: function getFormDataString(formEl) {
        const formData = new FormData(formEl),
            data = [];

        formData.set("marketplace_company", datacapture.companyName());
        
        for (let item of formEl.elements) {
            switch (item.name) {
                case "privacy":
                    if (item.checked) {
                        formData.set("privacy", "Privacy Policy Accepted");
                    }
                    break;
                case "_gotcha":
                case "inbox_key":
                    if (item.value !== "") {
                        formData.set(item.name, item.value);
                    }
                    break;
                default:

            }
        }

        for (let keyValuePair of formData) {
            // console.log("key value", keyValuePair[0], keyValuePair[1]);
            data.push(encodeURIComponent(keyValuePair[0]) + "=" + encodeURIComponent(keyValuePair[1]));
        }

        // for (let value of formData.values()) {
        //     console.log("values", value);
        // }

        return data.join("&");
    },

    initModal: function(marketplaceCompany) {
        
        const hirerNameDisplayEl = document.getElementById("hirer-name-display");
        const hirerNameSuccessEl = document.getElementById("hirer-name-success");
        hirerNameDisplayEl.innerText = marketplaceCompany;
        hirerNameSuccessEl.innerText = marketplaceCompany;
    },

    submitButtonText: function()  {
        // submit element value is not respected without this.
        const submitEl = document.getElementById("hirer-marketplace-demo-submit");
        submitEl.value = "Submit";
    },

    addClickListeners: function() {
        // for arrange demo links
        const els = document.getElementsByClassName("marketplace-company");
        for (let el of els) {
            el.addEventListener("click", datacapture.getData);
        }
    },

    addListenersToForm: function() {
        // Override the submit event
        const formEl = document.getElementById("hirer-marketplace-demo-form");
        formEl.addEventListener("submit", function (e) {

            e.preventDefault();

            // if (grecaptcha) {
            //     const recaptchaResponse = grecaptcha.getResponse();
            //     if (!recaptchaResponse) { // reCAPTCHA not clicked yet
            //         return false;
            //     }
            // }

            const request = new XMLHttpRequest();

            request.addEventListener("load", function () {

                // hide form
                const formEl = document.getElementById("hirer-marketplace-demo-form");
                formEl.classList.add("hidden");

                // show success
                const successEl = document.getElementById("hirer-marketplace-demo-success");
                successEl.classList.remove("hidden");

                if (request.status === 303) { // CloudCannon redirects on success
                    // It worked
                    // NO IT DOESN't
                }
            });

            const formEl = document.getElementById("hirer-marketplace-demo-form");

            request.open(formEl.method, formEl.action);
            request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            request.send(datacapture.getFormDataString(formEl));

        });
    },

    resetModal: function() {
        // hide form
        const formEl = document.getElementById("hirer-marketplace-demo-form");
        formEl.classList.remove("hidden");

        // show success
        const successEl = document.getElementById("hirer-marketplace-demo-success");
        successEl.classList.add("hidden");
    }
    
};

let marketplaceCompany;

datacapture.addClickListeners();
datacapture.addListenersToForm();